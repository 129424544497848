<template>
  <section class="py-4 px-5">
    <b-form-row v-if="restrictAddWidgetToMultipleCity">
      <b-col>
        <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_WIDGET_ERROR }}</b-alert>
      </b-col>
    </b-form-row>
    <h3 class="page-header">Widgets</h3>
    <b-form-row class="my-4 justify-content-between">
      <b-col lg="7" sm="7" cols="12">
        <div class="floating-input-field">
          <b-form-input
            id="form-input-search-widgets"
            class="mb-2 mb-sm-1"
            v-model="filter"
            @input="currentPage = 1"
            placeholder=" "
            type="text"
          ></b-form-input>
          <label for="form-input-search-widgets">Search widgets <BIconSearch class="input-search-icon" /></label>
        </div>
      </b-col>
      <b-col lg="5" sm="5" cols="12" class="mb-2 mb-sm-1 text-right">
        <b-button
          type="button"
          id="reorder-widgets-btn"
          class="mr-sm-2"
          v-activeBlur
          variant="primary"
          @click="showReorderWidgetsModal = true"
          :disabled="disableReorderWidgets"
          >Reorder widgets <RearrangeIcon class="ml-1"
        /></b-button>
        <b-button type="button" v-activeBlur variant="primary" @click="navigate()" :disabled="restrictAddWidgetToMultipleCity"
          >Add widget <BIconPlus scale="1.5"
        /></b-button>
      </b-col>
    </b-form-row>
    <!-- Widget table element -->
    <b-table
      :items="widgets"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @context-changed="setTableCache($event, 'widgets')"
      @row-dblclicked="onWidgetRowDblclicked"
      show-empty
      @filtered="onFiltered"
      hover
      class="table-transition"
      id="widgets-table"
      primary-key="widgetID"
      :tbody-transition-props="transProps"
      :busy="widgetsLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(widgetName)="row">
        <div class="preview-logo-wraper">
          <div
            class="preview-logo"
            :style="{
              backgroundColor: row.item.widgetColor,
            }"
          >
            <img :src="row.item.widgetLogoBlobURI" />
          </div>
          <div class="pl-2">{{ row.value }}</div>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-dropdown class="action-dropdown" no-caret variant="outline-secondary" :disabled="restrictAddWidgetToMultipleCity" right>
          <template #button-content>
            Actions
            <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
          </template>
          <b-dropdown-item v-if="row.item.isEditable" :to="`widgets/edit-widget/${row.item.widgetID}/${row.item.widgetCategoryID}`">Edit</b-dropdown-item>
          <b-dropdown-item href="javascript:void(0)" @click="deleteWidgetConfirmDailog(row.item)">Delete</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <!-- Table options-->
    <b-form-row>
      <b-col sm="6" md="6" lg="3" cols="12" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="5"
          label-cols-md="6"
          label-cols-lg="4"
          label-cols-xl="3"
          label-cols="3"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="d-none d-lg-block"></b-col>
      <b-col sm="6" md="6" lg="3" class="my-1">
        <b-pagination
          v-if="!widgetsLoading"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-form-row>
    <ReorderWidgetsModal v-if="showReorderWidgetsModal" @closeReorderWidgetsModal="showReorderWidgetsModal = false" />
    <ConfirmationModal
      :showModal="deleteWidgetConfirmModal"
      :title="`CONFIRM`"
      :message="`DELETE_WIDGET`"
      @onConfirm="deleteWidget"
      @closeConfirmModal="deleteWidgetConfirmModal = false"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { BIconPlus, BIconSearch, BIconChevronDown } from 'bootstrap-vue'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import RearrangeIcon from '@/assets/svg/rearrange-icon.svg'
import { useTableOptionsCache } from '@/composables/useTableOptionsCache'
export default {
  name: 'Widgets',
  setup() {
    const { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache } = useTableOptionsCache('widgets')
    return { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache }
  },
  data() {
    return {
      transProps: {
        // Transition name
        name: 'flip-list',
      },
      widgetsLoading: false,
      deleteWidgetConfirmModal: false,
      selectedWidget: null,
      fields: [
        { key: 'widgetIndex', label: 'Order', sortable: true, sortDirection: 'desc', class: 'order-column' },
        {
          key: 'widgetName',
          label: 'Widget name',
          sortable: true,
        },
        {
          key: 'widgetType',
          label: 'Type',
          sortable: true,
        },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: '' },
      ],
      totalRows: 1,
      pageOptions: [5, 10, 15, 25],
      sortDirection: 'asc',
      filterOn: ['widgetIndex', 'widgetName', 'widgetType'],
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      showReorderWidgetsModal: false,
    }
  },
  components: {
    BIconPlus,
    BIconSearch,
    BIconChevronDown,
    RearrangeIcon,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue'),
    ReorderWidgetsModal: () => import('./reorder-widgets/ReorderWidgetsModal.vue'),
  },
  computed: {
    getSelectedApplicableWidgets() {
      return this.applicableWidgets.filter((widget) => widget.isSelected === true)
    },
    restrictAddWidgetToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    disableReorderWidgets() {
      return this.restrictAddWidgetToMultipleCity || !this.widgets.length
    },
    ...mapState({
      widgets: (state) => state.widgets.widgets,
      selectedTenant: (state) => state.common.selectedTenant,
      userRole: (state) => state.common.userRole,
      applicableWidgets: (state) => state.widgets.applicableWidgets,
    }),
  },
  mounted() {
    this.getWidgets()
    this.getApplicableWidgets()
    if (this.userRole === 'SysAdmin') {
      this.fields.splice(2, 0, {
        key: 'city',
        label: 'City',
        sortable: true,
        sortDirection: 'desc',
      })
    }
  },
  created() {},
  methods: {
    navigate(widget) {
      this.$router.push('widgets/add-widgets')
    },
    onWidgetRowDblclicked(item) {
      if (item.isEditable) {
        if (!this.restrictAddWidgetToMultipleCity) {
          this.$router.push(`widgets/edit-widget/${item.widgetID}/${item.widgetCategoryID}`).catch(() => {})
        }
      }
    },
    getWidgets() {
      if (this.selectedTenant.length) {
        this.widgetsLoading = true
        this.$store
          .dispatch('widgets/getWidgets')
          .then((response) => {
            this.widgetsLoading = false
          })
          .catch((erro) => {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'WIDGETS_LIST_ERROR',
              type: 'danger',
            })
            this.widgetsLoading = false
            this.totalRows = 0
          })
      }
    },
    getApplicableWidgets() {
      if (this.selectedTenant.length && !this.restrictAddWidgetToMultipleCity) {
        this.$store.dispatch('widgets/getApplicableWidgets')
      } else {
        this.$store.commit('widgets/setApplicableWidgets', [])
      }
    },
    deleteWidgetConfirmDailog(item) {
      this.selectedWidget = item
      this.deleteWidgetConfirmModal = true
    },
    deleteWidget() {
      const postObj = {
        userID: 0,
        widgetID: this.selectedWidget.widgetID,
        comments: 'Deleting widget',
      }
      this.$store
        .dispatch('widgets/deleteWidget', postObj)
        .then((response) => {
          this.deleteWidgetConfirmModal = false
          if (response.data) {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'DELETE_WIDGET',
              type: 'success',
            })
            this.getWidgets()
            this.getApplicableWidgets()
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          }
        })
        .catch(() => {
          this.deleteWidgetConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.getWidgets()
      this.getApplicableWidgets()
    },
    widgets() {
      this.totalRows = this.widgets.length
      this.getTableCache('widgets')
    },
  },
}
</script>
<style lang="scss" scoped></style>
